import { Injectable } from '@angular/core';
import { ApiService, ApiSingleResult } from '@capturum/api';
import { SettingIndexedDbModel } from '@capturum/complete';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Setting } from '../models/setting.model';

@Injectable({
  providedIn: 'root',
})
export class SettingService extends ApiService<Setting> {
  protected endpoint = 'setting';

  public update(data: Setting): Observable<any> {
    if (typeof data?.value !== 'string') {
      data.value = JSON.stringify(data?.value);
    }

    return this.apiHttp.post<ApiSingleResult<any>>(`/${this.endpoint}/set/${data?.id}`, data).pipe(
      tap((response) => {
        SettingIndexedDbModel.query().put({ id: response?.data?.key, value: response?.data?.value });
        SettingIndexedDbModel.loadSettings().then(() => {
          return this.setGlobalStyles();
        });
      }),
    );
  }

  private setGlobalStyles(): void {
    const colorSettings = SettingIndexedDbModel.settings.filter((setting) => {
      return (setting?.id as string).includes('color');
    });

    for (const setting of colorSettings) {
      let key = setting?.id as string;

      if (key.includes('general.tenant_')) {
        key = key.split('general.tenant_')[1];
      }

      document.documentElement.style.setProperty(
        `--${key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase()}`,
        setting?.value as string,
      );
    }
  }
}
