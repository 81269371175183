<div class="content">
  <div class="content-body">
    <cap-data-table
      #dataTable
      #tt
      class="clickable"
      stateKey="setting-list"
      [sortable]="tableData?.length > 0"
      [columns]="columns"
      [clickable]="true"
      [filters]="filters"
      [loading]="loading"
      [paginator]="paginator"
      [pagination]="tableData"
      [tableValue]="tableData"
      [filtersStyle]="filtersStyle"
      [resettableFilters]="true"
      [resettableFiltersButton]="false"
      [defaultRowsPerPage]="10"
      [texts]="{ noResults: ('dxp.table.select-filter' | translate) }"
      (onFilter)="onFilter($event)"
      (onActionClick)="executeActions($event)"
      (onSort)="onTableSort($event)"
      (onRowClick)="onRowClick($event)"
      (onLazyLoad)="loadTableData($event)">
      <ng-template capTemplate="right-filters">
        <app-filter-buttons [entity]="entityConfig.name" (applyFilter)="applyFilter()" (resetFilter)="resetFilter()">
        </app-filter-buttons>
      </ng-template>
    </cap-data-table>
  </div>
</div>
