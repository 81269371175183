import { Component, Injector } from '@angular/core';
import { DxpBaseListComponent } from '@core/base/dxp-base-list.class';
import { Entity } from '@core/enums/entity.enum';
import { EntityConfig } from '@core/models/entity-config.model';
import { Setting } from '../../models/setting.model';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { SettingService } from '../../services/setting.service';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@capturum/ui/api';
import { LayoutConfig } from '@shared/services/layout-config.service';
import { TitleHelper } from '@core/utils/title.helper';

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
})
export class SettingListComponent extends DxpBaseListComponent<Setting> {
  public entityConfig: EntityConfig = {
    name: Entity.setting,
  };

  public constructor(
    public injector: Injector,
    public apiService: SettingService,
    public translateService: TranslateService,
    public notificationService: NotificationService,
    public layoutUtilsService: LayoutUtilsService,
  ) {
    super(injector, translateService, notificationService, layoutUtilsService);

    this.columns = this.getColumns();
  }

  protected overwriteDefaultConfig(entity: Setting, defaultConfig: LayoutConfig): LayoutConfig {
    return {
      ...defaultConfig,
      title: TitleHelper.concatTitles(
        [
          this.translateService.stream('dxp.manage.overview.title'),
          this.translateService.stream('config.setting-overview.label'),
        ],
        ' - ',
      ),
      actionButtons: [],
    };
  }

  private getColumns(): TableColumn[] {
    return TableColumn.getColumns([
      {
        field: 'name',
        header: this.translateService.stream('setting.overview.name'),
        styleClass: 'table-bold-col',
        filterable: true,
      },
      {
        field: 'description',
        header: this.translateService.stream('setting.overview.description'),
        filterable: true,
      },
      {
        field: 'type',
        header: this.translateService.stream('setting.overview.type'),
        filterable: false,
      },
      {
        field: 'value',
        header: this.translateService.stream('setting.overview.value'),
        filterable: false,
      },
    ]);
  }
}
